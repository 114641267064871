import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  is_null,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiLogout,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import { SMS_FUNC } from "config/CONSTANTS";
import { console_log } from "utils/logger";
import { updatePageData } from "redux/actions/pageDataActions";

const CompanyRegisterPage0 = (props) => {
  const { goRegStep } = props;
  const curStep = 0;
  const pageDataStore = useSelector((x) => x.pageDataStore);
  //console_log("=====================pageData=====================", pageDataStore["companyRegister"]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!is_null(pageDataStore["companyRegister"])) {
      setUserData(pageDataStore["companyRegister"]["step0"]);
    }
  }, [pageDataStore]);
  //////////////////////////////////////////////////////////////////
  const initUserData = {};
  const [userData, setUserData] = useState(initUserData)
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "name", errorList);
    errorList = isEmpty(userData, "phone", errorList);
    errorList = isEmpty(userData, "email", errorList);
    errorList = isEmpty(userData, "password", errorList);
    errorList = isEmpty(userData, "password1", errorList);
    errorList = isEmpty(userData, "address", errorList);
    errorList = isEmpty(userData, "country", errorList);
    errorList = isEmpty(userData, "city", errorList);
    errorList = isEmpty(userData, "zip_code", errorList);
    errorList = isEmpty(userData, "state", errorList);
    errorList = isEmpty(userData, "moved_address", errorList);
    errorList = isEmpty(userData, "company_website", errorList);
    errorList = isEmpty(userData, "contact_id", errorList);
    errorList = isEmpty(userData, "dob", errorList); 
    
    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updateData = { ...userData }
    updateData[field_name] = field_value;
    //console_log("userData:::::", updateData)
    setUserData({ ...updateData });
  };
  const onChangePhoneField = (value, country, e, formattedValue) => {
    let phone_number = trim_phone(formattedValue);
    if (errorField.includes("phone")) {
      let errors = errorField.filter((x) => x !== "phone");
      setErrorField([...errors]);
    }
    const updateData = { ...userData }
    updateData["phone"] = phone_number;
    setUserData({ ...updateData });
  };

  const dispatchPageData = () => {
    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          step0: userData
        },
      })
    );
  }

  const setUserBirthDate = (d) => {
    console_log(d);
    const updateData = { ...userData }
    updateData["dob"] = new Date(d);
    setUserData({ ...updateData });
  }

  const nextRegStep = () => {
    const is_valid = validateFields();
    if(is_valid) {
      if(userData['password'] !== userData['password1']) {
        showToast("Password does not match", "error")
        return false;
      }

      dispatchPageData()
      goRegStep(curStep + 1);
    }else{
      setTimeout(function () {
        if (jQuery(".is-invalid").length > 0) {
          console_log('jQuery(".is-invalid").offset().top', jQuery(".is-invalid").offset())
          const scrollTop = jQuery(".is-invalid").offset().top - 20
          window.scrollTo(0, (scrollTop > 0 ? scrollTop : 0));
        }
      }, 200)
    }
  }

  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  }

  return (
    <div className="reg-form">
      <div className="row">
        <div className="col-md-12">
          <div className="user-info-block">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Company Name:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("name") ? " is-invalid" : "")
                    }
                    id="name"
                    name="name"
                    placeholder="Company Name"
                    value={ get_data_value(userData, "name")}
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Company Website:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("company_website")
                        ? " is-invalid"
                        : "")
                    }
                    id="company_website"
                    name="company_website"
                    placeholder="Company Website"
                    value={
                      userData["company_website"]
                        ? userData["company_website"]
                        : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Skype ID / Whatapp / Telegram:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("contact_id") ? " is-invalid" : "")
                    }
                    id="contact_id"
                    name="contact_id"
                    placeholder="Skype ID / Whatapp / Telegram"
                    value={
                      userData["contact_id"] ? userData["contact_id"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Country:</label>
                  <select
                    id="country"
                    name="country"
                    className={
                      "form-control" +
                      (errorField.includes("country") ? " is-invalid" : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "country", "US")}
                  >
                    {countryList.map((countryItem, index) => (
                      <option key={index} value={countryItem.code}>
                        {countryItem.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Mobile Number:</label>
                  <div className="phone-number-box">
                    <PhoneInput
                      country={"us"}
                      value={get_data_value(userData, "phone")}
                      placeholder=""
                      onChange={(value, country, e, formattedValue) => {
                        onChangePhoneField(value, country, e, formattedValue);
                      }}
                      inputProps={{
                        type: "tel",
                        className: "form-control phone_number",
                        id: "phone",
                        name: "phone",
                        placeholder: "",
                      }}
                    />

                    <input type="hidden" name="phone_number" />
                    <input type="hidden" name="dial_code" />
                    <button
                      className="a-btn btn-remove-tel"
                      onClick={(e) => {
                        onChangePhoneField("", "us", e, "");
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Registration Date:</label>
                  <DatePicker
                    className={
                      "form-control" +
                      (errorField.includes("dob") ? " is-invalid" : "")
                    }
                    id="dob"
                    name="dob"
                    placeholder="MM/DD/YYYY"
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={new Date(1950, 1, 1)}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    selected={userData['dob']}
                    onChange={(d) => {
                      setUserBirthDate(d);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-address-block" id="user-address-block">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("address") ? " is-invalid" : "")
                    }
                    id="address"
                    name="address"
                    placeholder="Address"
                    value={
                      userData["address"] ? userData["address"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>State:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("state") ? " is-invalid" : "")
                    }
                    id="state"
                    name="state"
                    placeholder="State"
                    value={
                      userData["state"] ? userData["state"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>City:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("city") ? " is-invalid" : "")
                    }
                    id="city"
                    name="city"
                    placeholder="City"
                    value={
                      userData["city"] ? userData["city"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Zip Code:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("zip_code") ? " is-invalid" : "")
                    }
                    id="zip_code"
                    name="zip_code"
                    placeholder="Zip Code"
                    value={
                      userData["zip_code"] ? userData["zip_code"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Year Moved to Address:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("moved_address") ? " is-invalid" : "")
                    }
                    id="moved_address"
                    name="moved_address"
                    placeholder="Address"
                    value={
                      userData["moved_address"] ? userData["moved_address"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-credential-block" id="user-credential-block">
            <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
              Login Information:
            </h6>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className={
                      "form-control" +
                      (errorField.includes("email") ? " is-invalid" : "")
                    }
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={
                      userData["email"] ? userData["email"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errorField.includes("password") ? " is-invalid" : "")
                    }
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={
                      userData["password"] ? userData["password"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Confirm Password:</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errorField.includes("password1") ? " is-invalid" : "")
                    }
                    id="password1"
                    name="password1"
                    placeholder="Confirm Password"
                    value={
                      userData["password1"] ? userData["password1"] : ""
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyRegisterPage0;
