import CompanyUserDocumentBlock from "pages/Home/CompanyRegisterPage/inc/CompanyUserDocumentBlock";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  isEmpty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  is_null,
  get_data_value,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiLogout,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { SMS_FUNC } from "config/CONSTANTS";
import { console_log } from "utils/logger";
import { updatePageData } from "redux/actions/pageDataActions";
import CompanyAgreementModal from "./inc/CompanyAgreementModal";

const CompanyRegisterPage3 = (props) => {
  const { goRegStep, onSubmit } = props;
  const curStep = 3;

  const pageDataStore = useSelector((x) => x.pageDataStore);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!is_null(pageDataStore["companyRegister"]["step1"])) {
      setUserData(pageDataStore["companyRegister"]["step1"]);
      console_log(
        'pageDataStore["companyRegister"]["step1"]',
        pageDataStore["companyRegister"]["step1"]
      );
      const dataList = generateUserDocumentLists()
      console_log("dataList:::::::", dataList)
      setUserDocumentList(dataList)
      const initialErrorList = generateInitialErrorList()
      setErrorField(initialErrorList)
      console_log("initialErrorList:::::::", initialErrorList)
    }
  }, []);

  //////////////////////////////////////////////////////////////////
  const generateUserDocumentLists = () => {
    let dataList = []
    let share_holder_list_name_list = []
    let director_name_list = []
    let step1Data = pageDataStore["companyRegister"]["step1"]
    if (!empty(step1Data['share_holder_list'])) {
      for (let k in step1Data['share_holder_list']) {
        let row = step1Data['share_holder_list'][k]
        let name = row['first_name'] + " " + row['last_name']
        share_holder_list_name_list.push(name)
        if (true || !share_holder_list_name_list.includes(name)) {
          dataList.push({ name: name, doc_type: "passport" })
        }
      }
    }
    if (!empty(step1Data['director_list'])) {
      for (let k in step1Data['director_list']) {
        let row = step1Data['director_list'][k]
        let name = row['first_name'] + " " + row['last_name']
        director_name_list.push(name)
        if (!share_holder_list_name_list.includes(name)) {
          dataList.push({ name: name, doc_type: "passport" })
        }
      }
    }
    return dataList
  }

  const generateInitialErrorList = () => {
    let error_list = []
    const dataList = generateUserDocumentLists()
    for (let i = 0; i < dataList.length; i++) {
      error_list.push([])
    }
    return error_list
  }

  const [userDocumentList, setUserDocumentList] = useState([]);
  const [errorField, setErrorField] = useState([]);
  const setErrorFieldData = (data, index) => {
    let error_field = [...errorField];
    error_field[index] = [...data]
    setErrorField(error_field)
  }
  const setUsersFileData = (data, index) => {
    let user_document_list = [...userDocumentList];
    user_document_list[index] = { ...user_document_list[index], ...data }
    setUserDocumentList(user_document_list);
    dispatchPageData(user_document_list)
  }

  const [userData, setUserData] = useState({});
  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    userData[field_name] = checked;
    setUserData({ ...userData });
  }

  const validateFields = () => {
    let is_valid = true;
    let user_doc_list = [...userDocumentList]
    let newErrorField = []
    for (let i = 0; i < user_doc_list.length; i++) {
      let formData = user_doc_list[i]
      var errorList = [];
      errorList = isEmpty(formData, "doc_type", errorList);
      if (formData['doc_type'] === "passport") {
        errorList = isEmpty(formData, "upload_passport", errorList);
      } else if (formData['doc_type'] === "id_card") {
        errorList = isEmpty(formData, "upload_id_front", errorList);
        errorList = isEmpty(formData, "upload_id_back", errorList);
      }
      errorList = isEmpty(formData, "upload_utility", errorList);
      errorList = isEmpty(formData, "upload_bank", errorList);
      newErrorField.push([...errorList])

      // console_log("userDocumentList:::::::", userDocumentList, i)
      // console_log("formData:::::::", formData, i)
      // console_log("errorList:::::::", errorList, i)
      // console_log("errorField:::::::", errorField, i)

      if (errorList.length > 0) {
        is_valid = false
      }
    }
    setErrorField(newErrorField)
    return is_valid
  }

  const dispatchPageData = (document_data) => {
    if (empty(document_data)) {
      document_data = [...userDocumentList]
    }
    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          step3: document_data
        },
      })
    );
  }

  //////////////////////////////////////////////////////////////////////////////////

  const nextRegStep = () => {
    let is_valid = validateFields()
    if (is_valid) {
      if (!agreeContract) {
        showToast("Please confirm if you agree the Agreement", 'error')
        return false;
      }

      dispatchPageData()
      console_log("Complete registration");
      console_log("====userDocumentList=========", userDocumentList)

      onSubmit()
    } else {
      setTimeout(function () {
        if (jQuery(".is-invalid").length > 0) {
          console_log('jQuery(".is-invalid").offset().top', jQuery(".is-invalid").offset())
          const scrollTop = jQuery(".is-invalid").offset().top - 20
          window.scrollTo(0, (scrollTop > 0 ? scrollTop : 0));
        }
      }, 200)
    }

  };

  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  };

  const [visibleModal, setVisibleModal] = useState(false)
  const showAgreeModal = () => {
    setVisibleModal(true)
  }
  const [agreeContract, setAgreeContract] = useState(true) //true for testing
  const [sigDataUrl, setSigDataUrl] = useState("")
  const onAgreeContract = (sigDataUrl) => {
    setAgreeContract(true)
    setSigDataUrl(sigDataUrl)
    const updatedData = { ...pageDataStore["companyRegister"]['step0'] }
    updatedData['sigDataUrl'] = sigDataUrl;

    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          step0: updatedData,
        },
      })
    );
  }

  return (
    <div className="reg-form">
      <div className="row">
        <div className="col-md-12 main-font-color">
          <h6 className="main-font-color">Documents:</h6>
          <p className="text-left">
            In order to activate your account, My Broker FX requires
            identification documents. Please upload the shareholders and
            directors identification documents using the "Select files" button.
          </p>
          <div>
            <b>IMPORTANT :</b>&nbsp; The name and address on ALL documents must
            match and be clearly visible with all 4 corners showing (address
            does not apply with passwords). The Proof of Address and the Bank
            Statement cannot be older than 3 months. We accept PDF, PNG, JPEG
            file formats and they cannot exceed 2MB.
          </div>
        </div>
        <div className="col-md-12">
          <div className="company-user-document-list mt-4">
            <div className="share-holder-document-list">
              {!is_null(userDocumentList) &&
                userDocumentList.map((userDocInfo, index) => {
                  return (
                    <CompanyUserDocumentBlock
                      key={index}
                      userDocInfo={userDocInfo}
                      setFileData={(e) => setUsersFileData(e, index)}
                      errorField={errorField[index]}
                      setErrorField={(ee) => setErrorFieldData(ee, index)}
                    />
                  );
                })}
            </div>
            {/* <div className="director-document-list">
              {!is_null(userData["director_list"]) &&
                Object.keys(userData["director_list"]).map((val, index) => {
                  return (
                    <CompanyUserDocumentBlock
                      key={index}
                      userName={
                        userData["director_list"][val]["first_name"] +
                        " " +
                        userData["director_list"][val]["last_name"]
                      }
                    />
                  );
                })}
            </div> */}
          </div>
        </div>
        <div className="col-md-12">

          <div className="custom-checkbox form-group inline-block cursor-pointer" >
            <input
              type="checkbox"
              id="watched-compliance"
              name="watched_compliance"
              checked={agreeContract}
              onChange={() => { return false }}
            />
            <label htmlFor="watched-compliance">
              &nbsp;I've read and agreed to the <b className="text-blue">Company Agreement</b>
            </label>
          </div>

        </div>

        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Complete Registration
            </button>
          </div>
        </div>
      </div>

      <CompanyAgreementModal
        title=""
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        modalClass="agree-contract-modal"
        onAgreeContract={(sigDataUrl) => { onAgreeContract(sigDataUrl) }}
        userName={pageDataStore["companyRegister"]['step0']['name']}
      />

    </div>
  );
};

export default CompanyRegisterPage3;
