export const totalEstimatedNetWorthOptions = [
    {
        value: 0,
        text: "$0 - $10,000",
    },
    {
        value: 1,
        text: "$10,001 - $50,000",
    },
    {
        value: 2,
        text: "$50,001 - $100,000",
    },
    {
        value: 3,
        text: "$100,001+",
    },
];

export const totalEstimatedAnnualOptions = [
    {
        value: 0,
        text: "$0 - $10,000",
    },
    {
        value: 1,
        text: "$10,001 - $50,000",
    },
    {
        value: 2,
        text: "$50,001 - $100,000",
    },
    {
        value: 3,
        text: "$100,001 - $500,000",
    },
    {
        value: 4,
        text: "$500,000+",
    },
];

export const sourceIncomeOptions = [
    {
        value: 0,
        text: "Income from profession/emplyment",
    },
    {
        value: 1,
        text: "Company Ownership/Sale of Company Assets",
    },
    {
        value: 2,
        text: "Investments or Savings",
    },
    {
        value: 3,
        text: "Other",
    },
];

export const yesNoOptions = [
    {
        value: 1,
        text: "Yes",
    },
    {
        value: 0,
        text: "No",
    },
];

export const companyDocumentOptions = [
    {
        value: 0,
        text: "Articles of Organizations",
    },
    {
        value: 1,
        text: "Articles of Corporation",
    },
    {
        value: 2,
        text: "Trust",
    },
];

export const documentType = [
    {
        value: 'passport',
        text: "Passport",
    },
    {
        value: "id_card",
        text: "ID Card",
    }
];