import IndividualUserDocumentBlock from "pages/Home/IndividualRegisterPage/inc/IndividualUserDocumentBlock";

import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  isEmpty, jQuery, showToast,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import IndividualUserPreferencesBlock from "./inc/IndividualUserPreferencesBlock";
import IndividualUserInfoBlock from "./inc/IndividualUserInfoBlock";
import IndividualAgreementModal from "./inc/IndividualAgreementModal";

const IndividualRegisterPage0 = (props) => {
  const { pageData, setPageData, onSubmit } = props;

  const [userData, setUserData] = useState({});
  const [errorField, setErrorField] = useState([]);

  const [userGeneralData, setUserGeneralData] = useState({})
  const [userPreferenceData, setUserPreferenceData] = useState({})
  const [fileData, setFileData] = useState({})

  const validateFields = () => {
    var errorList = [];

    var formData = { ...userGeneralData }
    //console_log("userGeneralData::::", userGeneralData)

    errorList = isEmpty(formData, "first_name", errorList);
    errorList = isEmpty(formData, "last_name", errorList);
    errorList = isEmpty(formData, "phone", errorList);
    errorList = isEmpty(formData, "email", errorList);
    errorList = isEmpty(formData, "password", errorList);
    errorList = isEmpty(formData, "password1", errorList);
    errorList = isEmpty(formData, "address", errorList);
    errorList = isEmpty(formData, "country", errorList);
    errorList = isEmpty(formData, "city", errorList);
    errorList = isEmpty(formData, "zip_code", errorList);
    errorList = isEmpty(formData, "dob", errorList);

    formData = { ...userPreferenceData }
    errorList = isEmpty(formData, "political_exposed_person", errorList);
    errorList = isEmpty(formData, "involved_terrorist", errorList);
    errorList = isEmpty(formData, "source_wealth", errorList);
    errorList = isEmpty(formData, "watched_compliance", errorList);
    errorList = isEmpty(formData, "previous_experience_trading", errorList);
    errorList = isEmpty(formData, "exchange_security_years_experience", errorList);
    errorList = isEmpty(formData, "leverage_metals_years_experience", errorList);
    errorList = isEmpty(formData, "leverage_metals_frequancy_trading", errorList);
    errorList = isEmpty(formData, "commodities_years_experience", errorList);
    errorList = isEmpty(formData, "commodities_frequancy_trading", errorList);
    errorList = isEmpty(formData, "futures_years_experience", errorList);
    errorList = isEmpty(formData, "futures_frequancy_trading", errorList);
    errorList = isEmpty(formData, "purpose_account", errorList);
    errorList = isEmpty(formData, "manage_account", errorList);
    //errorList = isEmpty(formData, "purpose_account_other", errorList);

    formData = { ...fileData }
    errorList = isEmpty(formData, "doc_type", errorList);
    if (formData['doc_type'] === "passport") {
      errorList = isEmpty(formData, "upload_passport", errorList);
    } else if (formData['doc_type'] === "id_card") {
      errorList = isEmpty(formData, "upload_id_front", errorList);
      errorList = isEmpty(formData, "upload_id_back", errorList);
    }
    errorList = isEmpty(formData, "upload_utility", errorList);
    errorList = isEmpty(formData, "upload_bank", errorList);

    console_log("errorList:::", errorList)

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updatedData = { ...userData }
    updatedData[field_name] = checked;
    setUserData({ ...updatedData });
  };

  const onClickRegister = () => {
    let is_valid = validateFields();
    if (true || is_valid) {
      if (!userPreferenceData['watched_compliance']) {
        showToast("Please confirm if you agree decralation", 'error')
        return false;
      }
      if (!agreeContract) {
        showToast("Please confirm if you agree Individual Client Agreement", 'error')
        return false;
      }

      const updatedData = {
        ...userData,
        userGeneralData: userGeneralData,
        userPreferenceData: userPreferenceData,
        fileData: fileData,
      }
      setUserData(updatedData);
      const newPageData = {
        ...pageData,
        userData: updatedData
      };
      setPageData(newPageData)
      onSubmit(newPageData)
    } else {
      console_log("userGeneralData, userPreferenceData, fileData", userGeneralData, userPreferenceData, fileData)
      setTimeout(function () {
        if (jQuery(".is-invalid").length > 0) {
          console_log('jQuery(".is-invalid").offset().top', jQuery(".is-invalid").offset())
          const scrollTop = jQuery(".is-invalid").offset().top - 20
          window.scrollTo(0, (scrollTop > 0 ? scrollTop : 0));
        }
      }, 200)
    }
  }

  const [visibleModal, setVisibleModal] = useState(false)
  const showAgreeModal = () => {
    setVisibleModal(true)
  }
  const [agreeContract, setAgreeContract] = useState(true) //true is for testing
  const [sigDataUrl, setSigDataUrl] = useState("")
  const onAgreeContract = (sigDataUrl) =>{
    setAgreeContract(true)
    setSigDataUrl(sigDataUrl)
    const updatedData = { ...userData }
    updatedData['watched_compliance1'] = true;
    updatedData['sigDataUrl'] = sigDataUrl;
    setUserData({ ...updatedData });
  }

  return (
    <div className="reg-form main-font-color">

      <IndividualUserInfoBlock
        setUserGeneralData={(e) => setUserGeneralData(e)}
        errorField={errorField}
        setErrorField={setErrorField}
      />

      <IndividualUserPreferencesBlock
        setUserPreferenceData={(e) => setUserPreferenceData(e)}
        errorField={errorField}
        setErrorField={setErrorField}
      />

      <IndividualUserDocumentBlock
        setFileData={(e) => setFileData(e)}
        errorField={errorField}
        setErrorField={setErrorField}
      />

      <div className="row">
        <div className="col-md-12">
          <div className="custom-checkbox form-group inline-block cursor-pointer"  >
            <input
              type="checkbox"
              id="watched-compliance1"
              name="watched_compliance1"           
              checked={agreeContract}
              onChange={()=>{return false}}
            />
            <label htmlFor="watched-compliance1">
              &nbsp;I've read and agreed to the <b className="text-blue">Individual Client Agreement</b>
            </label>
          </div>
        </div>

        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                onClickRegister();
              }}
            >
              Complete Registration
            </button>
          </div>
        </div>
      </div>

      <IndividualAgreementModal
        title=""
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        modalClass="agree-contract-modal"
        onAgreeContract={(sigDataUrl)=>{onAgreeContract(sigDataUrl)}}
        userName={userGeneralData['first_name']}
      />

    </div>
  );
};

export default IndividualRegisterPage0;

