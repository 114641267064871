
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  get_data_value,
  isEmpty,
  showToast,
  show_loading,
  trim_phone,
  ValidateEmail,
} from "utils/GlobalFunctions";
import {
  apiSendAuthSms,
} from "services/loginService";
import { countryList } from "utils/countryList";
import { console_log } from "utils/logger";

const IndividualUserInfoBlock = (props) => {
  const { setUserGeneralData, errorField, setErrorField } = props;

  const initUserData = {
    name: "quanseng",
    first_name: "quan",
    last_name: "seng",
    phone: "1232323232",
    email: "quanseng@gmail.com",
    password: "123",
    password1: "123",
    address: "address",
    country: countryList[0]['code'],
    city: "city",
    zip_code: "1234",
    dob: new Date()
  };
  const [userData, setUserData] = useState(initUserData);

  useEffect(() => {
    setUserGeneralData(userData)
  }, [userData]);

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updateData = { ...userData }
    updateData[field_name] = field_value;
    setUserData({ ...updateData });
    setUserGeneralData(updateData)
  };

  const onChangePhoneField = (value, country, e, formattedValue) => {
    let phone_number = trim_phone(formattedValue);
    if (errorField.includes("phone")) {
      let errors = errorField.filter((x) => x !== "phone");
      setErrorField([...errors]);
    }
    const updateData = { ...userData }
    updateData["phone"] = phone_number;
    setUserData({ ...updateData });
    setUserGeneralData(updateData)
  };

  const setUserBirthDate = (d) => {
    console_log(d);
    const updateData = { ...userData }
    updateData["dob"] = new Date(d);
    setUserData({ ...updateData });
    setUserGeneralData(updateData)
  }

  return (
    <div className="account-info-block">
      <div className="row">
        <div className="col-md-12">
          <div className="user-info-block">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className={
                      "form-control" +
                      (errorField.includes("email") ? " is-invalid" : "")
                    }
                    id="email"
                    name="email"
                    value={get_data_value(userData, 'email')}
                    placeholder="example@email.com"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errorField.includes("password") ? " is-invalid" : "")
                    }
                    id="password"
                    name="password"
                    value={get_data_value(userData, 'password')}
                    placeholder="Password"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Confirm Password:</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errorField.includes("password1") ? " is-invalid" : "")
                    }
                    id="password1"
                    name="password1"
                    value={get_data_value(userData, 'password1')}
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>First Name:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("first_name") ? " is-invalid" : "")
                    }
                    id="first_name"
                    name="first_name"
                    value={get_data_value(userData, 'first_name')}
                    placeholder="First Name"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("last_name") ? " is-invalid" : "")
                    }
                    id="last_name"
                    name="last_name"
                    value={get_data_value(userData, 'last_name')}
                    placeholder="Last Name"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Date of Birth:</label>
                  <DatePicker
                    className={
                      "form-control" +
                      (errorField.includes("dob") ? " is-invalid" : "")
                    }
                    id="dob"
                    name="dob"
                    placeholder="MM/DD/YYYY"
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={new Date(1950, 1, 1)}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    selected={userData['dob']}
                    onChange={(d) => {
                      setUserBirthDate(d);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Phone Number:</label>
                  <div className="phone-number-box">
                    <PhoneInput
                      country={"us"}
                      value={get_data_value(userData, "phone")}
                      placeholder=""
                      onChange={(value, country, e, formattedValue) => {
                        onChangePhoneField(value, country, e, formattedValue);
                      }}
                      inputProps={{
                        type: "tel",
                        className: "form-control phone_number",
                        id: "phone",
                        name: "phone",
                        placeholder: "",
                      }}
                    />

                    <input type="hidden" name="phone_number" />
                    <input type="hidden" name="dial_code" />
                    <button
                      className="a-btn btn-remove-tel"
                      onClick={(e) => {
                        onChangePhoneField("", "us", e, "");
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-address-block" id="user-address-block">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("address") ? " is-invalid" : "")
                    }
                    id="address"
                    name="address"
                    value={get_data_value(userData, 'address')}
                    placeholder="Address"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Country:</label>
                  <select
                    id="country"
                    name="country"
                    className={
                      "form-control" +
                      (errorField.includes("country") ? " is-invalid" : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "country", "US")}
                  >
                    {countryList.map((countryItem, index) => (
                      <option key={index} value={countryItem.code}>
                        {countryItem.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>City:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("city") ? " is-invalid" : "")
                    }
                    id="city"
                    name="city"
                    value={get_data_value(userData, 'city')}
                    placeholder="City"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Zip Code:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("zip_code") ? " is-invalid" : "")
                    }
                    id="zip_code"
                    name="zip_code"
                    value={get_data_value(userData, 'zip_code')}
                    placeholder="Zip Code"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualUserInfoBlock;
