import {
  ROUTE_REGISTER,
  ROUTE_ROOT,
  ROUTE_USER_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { isMobile } from "utils/GlobalFunctions";
import { HOME_MENU_ITEMS } from "./HomeMenuItems";

const HomeNavbar = () => {
  const appDataStore = useSelector((x) => x.appDataStore);
  let is_mobile = isMobile();
  return (
    <div className="navigation-bar">
      <div className="t-show-desktop">
        <header className="Header_header___Yglu">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <Link to={ROUTE_ROOT} className="navbar-brand">
              <img className="banner-logo" src="/assets/home/img/logobanner.png" alt="img" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                {HOME_MENU_ITEMS.map((menuItem, index) => (
                  <li key={index} className="nav-item">
                    <>
                      {menuItem.route_type === 'a' && (
                        <a href={menuItem.route}
                          activeclassname="active"
                          className={`nav-link ${appDataStore.current_route === menuItem.route
                            ? "active1"
                            : ""
                            }`}>{menuItem.title}</a>
                      )}
                      {menuItem.route_type === 'link' && (
                        <Link
                          to={menuItem.route}
                          activeclassname="active"
                          className={`nav-link ${appDataStore.current_route === menuItem.route
                            ? "active1"
                            : ""
                            }`}
                        >
                          {menuItem.title}
                        </Link>
                      )}
                    </>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </header>
      </div>
      <div className="t-show-mobile">
        <div className="apple-mobile-menu">
          <div className="header">
            <div className="icon-container">
              <div id="menuicon">
                <div className="bar bar1"></div>
                <div className="bar bar2"></div>
              </div>
            </div>
            <div className="app-navbar-brand">
              <a className="" href="#">
                <img className="banner-logo" src="/assets/home/img/logobanner.png" alt="img" />
              </a>
            </div>
            <div className="mobile-menu">
              <ul className="menu">
                {HOME_MENU_ITEMS.map((menuItem, index) => (
                  <li key={index} className="menu-item">
                    <>
                      {menuItem.route_type === 'a' && (
                        <a href={menuItem.route}
                          activeclassname="active"
                          className={`${appDataStore.current_route === menuItem.route
                            ? "active1"
                            : ""
                            }`}>{menuItem.title}</a>
                      )}
                      {menuItem.route_type === 'link' && (
                        <Link
                          to={menuItem.route}
                          activeclassname="active"
                          className={`${appDataStore.current_route === menuItem.route
                            ? "active1"
                            : ""
                            }`}
                        >
                          {menuItem.title}
                        </Link>
                      )}
                    </>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNavbar;
