
export const ROOT = "/";
export const ROUTE_ROOT = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";
export const ROUTE_INDIVIDUAL_REGISTER = "/individual-register";
export const ROUTE_COMPANY_REGISTER = "/company-register";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_CONFIRM_PASSWORD = "/confirm-password";
export const ROUTE_ACCOUNT_VERIFICATION = "/verification";



export const ROUTE_USER_SOFTWARE = "/user/software";
export const ROUTE_USER_DASHBOARD = "/user/dashboard";

export const ROUTE_ADMIN_DASHBOARD = "/admin/dashboard";

