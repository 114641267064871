import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_null,
} from "utils/GlobalFunctions";
import { countryList } from "utils/countryList";
import { console_log } from "utils/logger";
import { updatePageData } from "redux/actions/pageDataActions";

const CompanyUserBlock = (props) => {
  const { boxNum, boxClass, boxName } = props;
  const pageDataStore = useSelector((x) => x.pageDataStore);
  //console_log("=====================pageData step1 UserBlock=====================", pageDataStore["companyRegister"]);

  const dispatch = useDispatch();
  const initUserData = {
    first_name: "quan",
    last_name: "seng",
    phone: "1232323232",
    email: "quanseng@gmail.com",   
    address: "address",
    country: countryList[0]['code'],
    city: "city",
    zip_code: "1234",
    state: "state",
    moved_address: "moved_address",
    dob: new Date(),
    errorField: []
  }
  useEffect(() => {
    if (boxName !== "Director") {
      if (!is_null(pageDataStore["companyRegister"]["step1"]['share_holder_list']) && !is_null(pageDataStore["companyRegister"]["step1"]['share_holder_list'][boxNum])) {
        setUserData(pageDataStore["companyRegister"]["step1"]['share_holder_list'][boxNum]);
        //console_log('errorrrrrrrrrrrrrrrrrrrrrrrrrrr::::', pageDataStore["companyRegister"]["step1"]['share_holder_list'][boxNum]['errorField'])
        if(!empty(pageDataStore["companyRegister"]["step1"]['share_holder_list'][boxNum]['errorField'])){
          setErrorField(pageDataStore["companyRegister"]["step1"]['share_holder_list'][boxNum]['errorField']);        
        }else{
          setErrorField([])
        }
      } else {
        setUserData(initUserData)
        dispatchPageData(initUserData)
      }
    } else {
      if (!is_null(pageDataStore["companyRegister"]["step1"]['director_list']) && !is_null(pageDataStore["companyRegister"]["step1"]['director_list'][boxNum])) {
        setUserData(pageDataStore["companyRegister"]["step1"]['director_list'][boxNum]);
        
        if(!empty(pageDataStore["companyRegister"]["step1"]['director_list'][boxNum]['errorField'])){
          setErrorField(pageDataStore["companyRegister"]["step1"]['director_list'][boxNum]['errorField']);        
        }else{
          setErrorField([])
        }
      } else {
        setUserData(initUserData)
        dispatchPageData(initUserData)
      }
    }
  }, [pageDataStore]);


  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);


  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    console_log("userData", userData);

    dispatchPageData()
    dispatchErrorData([...errorField])
  }

  const setUserBirthDate = (d) => {
    console_log(d);
    const field_name = "dob"
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = new Date(d);
    dispatchPageData()
    dispatchErrorData([...errorField])
  }

  const dispatchPageData = (user_data) => {
    if(empty(user_data)) {
      user_data = {...userData}
    }
    let tmpData = pageDataStore["companyRegister"]
    if (boxName !== "Director") {
      tmpData["step1"]['share_holder_list'][boxNum] = user_data
    } else {
      tmpData["step1"]['director_list'][boxNum] = user_data
    }
    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          ...tmpData
        },
      })
    );
  }

  const dispatchErrorData = (error_field) => {
    if(empty(error_field)) {
      error_field = {...errorField}
    }
    let tmpData = {...pageDataStore["companyRegister"]}
    if (boxName !== "Director") {
      tmpData["step1"]['share_holder_list'][boxNum]['errorField'] = error_field
    } else {
      tmpData["step1"]['director_list'][boxNum]['errorField'] = error_field
    }
    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          ...tmpData
        },
      })
    );
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  return (
    <div className={`company-user-box ${boxClass}`}>
      <h6 className="main-font-color text-center">
        {boxName} - {boxNum}
      </h6>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("first_name") ? " is-invalid" : "")
              }
              id="first_name"
              name="first_name"
              placeholder="First Name"
              value={
                userData["first_name"] ? userData["first_name"] : ""
              }
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("last_name") ? " is-invalid" : "")
              }
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              value={
                userData["last_name"] ? userData["last_name"] : ""
              }
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Date of Birth:</label>
            <DatePicker
              className={
                "form-control" +
                (errorField.includes("dob") ? " is-invalid" : "")
              }
              id="dob"
              name="dob"
              placeholder="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              maxDate={new Date()}
              minDate={new Date(1950, 1, 1)}
              yearDropdownItemNumber={100}
              scrollableYearDropdown={true}
              showYearDropdown
              selected={userData['dob']}
              onChange={(d) => {
                setUserBirthDate(d);
              }}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("address") ? " is-invalid" : "")
              }
              id="address"
              name="address"
              placeholder="Address"
              value={get_data_value(userData, 'address')}
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>State:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("state") ? " is-invalid" : "")
              }
              id="state"
              name="state"
              placeholder="State"
              value={get_data_value(userData, 'state')}
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>City:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("city") ? " is-invalid" : "")
              }
              id="city"
              name="city"
              placeholder="City"
              value={get_data_value(userData, 'city')}
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Zip Code:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("zip_code") ? " is-invalid" : "")
              }
              id="zip_code"
              name="zip_code"
              placeholder="Zip Code"
              value={get_data_value(userData, 'zip_code')}
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Country:</label>
            <select
              id="country"
              name="country"
              className={
                "form-control" +
                (errorField.includes("country") ? " is-invalid" : "")
              }
              onChange={(e) => {
                onChangeFormField(e);
              }}
              value={get_data_value(userData, "country", "US")}
            >
              {countryList.map((countryItem, index) => (
                <option key={index} value={countryItem.code}>
                  {countryItem.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Year Moved to Address:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("moved_address") ? " is-invalid" : "")
              }
              id="moved_address"
              name="moved_address"
              placeholder="Address"
              value={get_data_value(userData, 'moved_address')}
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyUserBlock;
