import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import SignatureCanvas from 'react-signature-canvas'
import { isEmpty } from "utils/GlobalFunctions";
const SignatureForm = (props) => {
  const { sigDataUrl, setSigDataUrl } = props;

  useEffect(() => {
    onEndSignature()
  }, [])

  const setSigEmpty = (is_empty) => {
    if (is_empty) {
      setSigDataUrl("")
    }
  }
  const sigPad = useRef({})
  const clear = () => {
    sigPad.current.clear()
    setSigEmpty(true)
  }
  const trim = () => {
    const dataUrl = sigPad.current.getTrimmedCanvas().toDataURL('image/png')
    //console_log("dataUrl:::", dataUrl)
    setSigDataUrl(dataUrl)
  }
  const onEndSignature = (e) => {
    //console_log("onEndSignature:::", e)
    const sigIsEmpty = sigPad.current.isEmpty()
    setSigEmpty(sigIsEmpty)
    if (!sigIsEmpty) {
      trim()
    }
  }

  return (
    <div className="signature-container text-center">
      <div className="signature-box" style={{ maxWidth: '500px' }}>
        <SignatureCanvas
          penColor='blue'
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          ref={(ref) => { sigPad.current = (ref) }}
          onEnd={(e) => onEndSignature(e)}
        />
      </div>
      <div className="sigBtnBox text-center p-1">
        <button type="button" className={`btn btn-dark btn-sm`} onClick={(e) => { clear() }}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default SignatureForm;
