import MyUploadBox from "components/MyUploadBox/MyUploadBox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  get_data_value,
  isEmpty,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { documentType } from "../constants";

const IndividualUserDocumentBlock = (props) => {
  const { setFileData, errorField, setErrorField } = props;

  const initUserData = {
    doc_type: "passport",
  };
  const [userData, setUserData] = useState(initUserData);

  useEffect(() => {
    setFileData(userData)
  }, [userData]);

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updateData = { ...userData }
    updateData[field_name] = field_value;
    setUserData({ ...updateData });
    setFileData(updateData)
  };
  const onChangeFile = (files, field_name, single = true) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updateData = { ...userData }
    if (files && files.length > 0) {
      updateData[field_name] = files[0];
    }
    setUserData({ ...updateData });
    setFileData(updateData)
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 pt-3">
            <h6 className="main-font-color">Documents:</h6>
            <p className="text-left">
              In order to activate your account, My Broker FX requires
              identification documents. Please upload the shareholders and
              directors identification documents using the "Select files"
              button.
            </p>
            <div>
              <b>IMPORTANT :</b>&nbsp; The name and address on ALL documents
              must match and be clearly visible with all 4 corners showing
              (address does not apply with passwords). The Proof of Address
              and the Bank Statement cannot be older than 3 months. We accept
              PDF, PNG, JPEG file formats and they cannot exceed 2MB.
            </div>
          </div>
          <div className="col-md-12">
            <div className="company-user-document-list mt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Please select which type of document you will upload:</label>
                    <select
                      id="doc_type"
                      name="doc_type"
                      className={
                        "form-control appearence" +
                        (errorField.includes("doc_type")
                          ? " is-invalid"
                          : "")
                      }
                      onChange={(e) => {
                        onChangeFormField(e);
                      }}
                      value={get_data_value(userData, "doc_type", "passport")}
                    >
                      {/* <option value="">Select</option> */}
                      {documentType.map((optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className={`row ${userData['doc_type'] === 'passport' ? "" : "hidden"}`}>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Passport:</label>
                    <div className={`file-upload-group ${(errorField.includes("upload_passport") ? "is-invalid" : "")}`}>
                      <MyUploadBox
                        multiple={false}
                        accept={
                          {
                            'image/jpeg': [],
                            'image/png': [],
                            'application/pdf':[],
                          }
                        }
                        callbackAcceptedFiles={(files) => {
                          console_log("callbackAcceptedFiles:::", files)
                          onChangeFile(files, 'upload_passport')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`row ${userData['doc_type'] === 'passport' ? "hidden" : ""}`}>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ID Front:</label>
                    <div className={`file-upload-group ${(errorField.includes("upload_id_front") ? "is-invalid" : "")}`}>
                      <MyUploadBox
                        callbackAcceptedFiles={(files) => {
                          console_log("callbackAcceptedFiles:::", files)
                          onChangeFile(files, 'upload_id_front')
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ID Back:</label>
                    <div className={`file-upload-group ${(errorField.includes("upload_id_back") ? "is-invalid" : "")}`}>
                      <MyUploadBox
                        callbackAcceptedFiles={(files) => {
                          console_log("callbackAcceptedFiles:::", files)
                          onChangeFile(files, 'upload_id_back')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr3" />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Utility Statement (Not older than 3 months):</label>
                    <div className={`file-upload-group ${(errorField.includes("upload_utility") ? "is-invalid" : "")}`}>
                      <MyUploadBox
                        callbackAcceptedFiles={(files) => {
                          console_log("callbackAcceptedFiles:::", files)
                          onChangeFile(files, 'upload_utility')
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Bank Statement (Not older than 3 months):</label>
                    <div className={`file-upload-group ${(errorField.includes("upload_bank") ? "is-invalid" : "")}`}>
                      <MyUploadBox
                        callbackAcceptedFiles={(files) => {
                          console_log("callbackAcceptedFiles:::", files)
                          onChangeFile(files, 'upload_bank')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  );
};

export default IndividualUserDocumentBlock;
