import {
  ROUTE_LOGIN,
} from "navigation/CONSTANTS";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import TermsConditionsModal from "./TermsConditionsModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";

const HomeFooter = () => {

  const [visibleTermsConditionsModal, setVisibleTermsConditionsModal] = useState(false);
  const [visiblePrivacyPolicyModal, setVisiblePrivacyPolicyModal] = useState(false);

  const showTermsConditions = () => {
    setVisibleTermsConditionsModal(true);
  };
  const showPrivacyPolicy = () => {
    setVisiblePrivacyPolicyModal(true);
  };

  return (
    <div className="landing-page-footer landing_black" id="contact">
      <div className="landing_container footer-section">
        <div className="footer-body">
          <div className="row">
            <div className="col-md-3">
              <div className="contact-address-block">
                <p className="footer-text">Address:</p>
                <p className="footer-text">
                  My Broker FX LLC <br />
                  First Floor, First St Vincent Bank LTD Building <br />
                  James Street <br />
                  Kingstown <br />
                  St. Vincent and the Grenadines <br />
                  Telephone: + 1 784 485 6124 <br />
                  Fax: + 1 784 485 6124 <br />
                </p>
                <p className="footer-text">Mailing Address:</p>
                <p className="footer-text">
                  P.O. Box 1574 <br />
                  Kingstown, VC0100 <br />
                  St. Vincent and the Grenadines <br />
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <p className="footer-text Text_container__fiiSE Text_body20__e5y_H mt-4">
                Risk Warning: CFDs are margined products; it is possible to lose more than your initial margin deposit or credit allocation as well as any variation margin that you may be required to deposit from time to time. Therefore, you should only speculate with money that you can afford to lose. CFD trading may not be suitable for all customers; therefore, please ensure that you fully understand the risks involved and seek independent advice if necessary and prior to entering into such transactions. When trading CFDs with MYBROKER FX, you are merely trading on the outcome of a financial instrument and therefore do not take delivery of any underlying instrument, nor are you entitled to any dividends payable or any other benefits related to the same. Please be advised, the services and products described on www.mybrokerfx.com and offered by MYBROKER FX are not being offered within Canada, the United States or Belgium and not being offered to U.S., Canadian and/or Belgium residents or citizens, as defined under applicable law. MYBROKER FX and its products and services offered on the site www.mybrokerfx.com are NOT registered or regulated by any U.S. or Canadian regulator and not regulated by FINRA, SEC, NFA or CFTC. MYBROKER FX reserves the right to retroactively charge storage fees for positions held for extended period of time as stipulated by the market liquidity provider.
              </p>
            </div>
            <div className="col-md-3">
              <div className="footer-img-block text-center">
                <img src="/assets/home/img/download.png" className="footer-s-logo img-responsive" />
                <img src="/assets/home/img/logobanner.png" className="footer-logo img-responsive" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-navbar">
          <button className="a-btn" onClick={(e) => { showTermsConditions() }} >Terms & Conditions</button>
          <button className="a-btn" onClick={(e) => { showPrivacyPolicy() }} >Privacy Policy</button>
          <Link to={ROUTE_LOGIN}>Login</Link>
          <a href="https://accounts.mybrokerfx.com/recover">Forgot Your Password?</a>
        </div>
        <div className="footer-banner">
          <p>© 2021 All rights reserved - My Broker FX</p>
        </div>
      </div>

      <TermsConditionsModal
        modalTitle={`Terms and Conditions`}
        isVisible={visibleTermsConditionsModal}
        setVisibleModal={setVisibleTermsConditionsModal}
        modalClass="home-page terms-conditions-modal"
      />

      <PrivacyPolicyModal
        modalTitle={`Notice of privacy`}
        isVisible={visiblePrivacyPolicyModal}
        setVisibleModal={setVisiblePrivacyPolicyModal}
        modalClass="home-page privacy-policy-modal"
      />

    </div>
  );
};

export default HomeFooter;
