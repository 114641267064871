import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_LOGIN, ROUTE_REGISTER,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import HomeNavbar from "layouts/HomeLayout/Includes/HomeNavbar";
import { TickerTape } from "react-tradingview-embed";

const HomePage = (props) => {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);
  const initPage = () => {
    let page_title = "Homepage";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_LOGIN,
      })
    );
  };

  useEffect(() => {
    initPage();
  }, []);

  //////////////////////////////////////////////////////////////////
  const tradingViewSymbols = [
    {
      description: "EURUSD",
      proName: "FX:EURUSD",
    },
    {
      description: "USDJPY",
      proName: "FX:USDJPY",
    },
    {
      description: "AUDUSD",
      proName: "FX:AUDUSD",
    },
    {
      description: "GBPCAD",
      proName: "FX:GBPCAD",
    },
    {
      description: "GBPUSD",
      proName: "FX:GBPUSD",
    },
    {
      description: "GBPJPY",
      proName: "FX:GBPJPY",
    },
    {
      description: "NZDCAD",
      proName: "FX:NZDCAD",
    },
    {
      description: "NZDUSD",
      proName: "FX:NZDUSD",
    },
    {
      description: "USDCAD",
      proName: "FX:USDCAD",
    },
    {
      description: "XAUUSD",
      proName: "OANDA:XAUUSD",
    },
    {
      description: "AUDCAD",
      proName: "FX:AUDCAD",
    },
  ];

  return (
    <div>
      <HomeLayout>
        <div className="landing-page-section landing_black">
          <div className="landing_container__tKlKL bg-img">
            <HomeNavbar></HomeNavbar>

            <div className="landing_title__Wi9gv">
              <div className="landing_desktop__8ZMwE">
                <h1 className="banner-main-text">
                  The Pure Essence of the Market<br />
                  Your #1 FX Broker!
                </h1>
                <p className="Text_container__fiiSE Text_body20__e5y_H">
                  My Broker FX was established by a group of serial entrepreneurs dedicated to providing the highest level of trading experience globally. Our clients get the best opportunities available in financial markets. We’ve gathered the best technology and services to make sure every trader finds what they are looking for.
                </p>
              </div>
            </div>
            <div className="landing_form__MW3CM">
              <Link to={ROUTE_REGISTER} className="btn btn-danger btn-lg">Register</Link>
              <p className="Text_container__fiiSE Text_body16__qNlL1 landing_info__rgYUL">Or just create a demo account...</p>
            </div>
            <div className="landing_promo__sEK76">
              <div className="landing_ipad__UiDcp">
                <img src="/assets/home/img/simple-ipad.png" className="animate__fadeIn" alt="img" />
              </div>
              <div className="landing_pencil__2fdjE">
                <p className="Text_container__fiiSE Text_body16__qNlL1">The Best Trading Experience Ever!</p>
              </div>
              <div className="react-reveal landing_shadow_1__QldP0"></div>
            </div>
            <div className="landing_appFor__L5ixc" id="why-choose">
              <p className="Text_container__fiiSE Text_caption20__XC_FX">Why choose my broker fx?</p>
              <h1>
                We offer 250+ financial instruments while providing the highest possible accuracy in prices. Integrity and no restriction on trading is what makes us unique!
              </h1>
              <h1 className="landing_highlight__KZoA5">Read More Below!</h1>
            </div>
          </div>

          <div className="landing-trading-view-block">
            <TickerTape
              widgetProps={{
                theme: "dark",
                colorTheme: "dark",
                isTransparent: true,
                displayMode: "adaptive",
                locale: "en",
                symbols: tradingViewSymbols,
              }}
            ></TickerTape>
          </div>

          <div className="landing_container__tKlKL">
            <div className="landing_blocks landing_blocks_sm">
              <div className="landing_block">
                <div className="landing_info__rgYUL">
                  <div className="landing_sub__1_yd6">
                    <p className="landing_block_subtitle Text_container__fiiSE Text_caption16__dSWBt ">The list goes on...</p>
                  </div>
                  <h3 className="landing_block_title">Highest Accuracy in Prices.</h3>
                  <p className="landing_block_desc">
                    We receive quotations from many sources of liquidity, which gives us the ability to be extremely fast and precise.
                  </p>
                </div>
              </div>
              <div className="landing_block">
                <div className="landing_info__rgYUL">
                  <h3 className="landing_block_title">ZERO Commissions.</h3>
                  <p className="landing_block_desc">
                    Our trade commissions on FX pairs are ZERO! Our commission is already included in the spread and we do not charge any other fees.
                  </p>
                </div>
              </div>
              <div className="landing_block">
                <div className="landing_info__rgYUL">
                  <h3 className="landing_block_title">All EAs, Scalping & Hedging Allowed.</h3>
                  <p className="landing_block_desc">
                    Enjoy the highest level of freedom!
                  </p>
                </div>
              </div>
            </div>
            <div className="landing_blocks">
              <div className="landing_block">
                <div className="row">
                  <div className="col-md-5">
                    <div className="landing_info__rgYUL">
                      <div className="landing_sub__1_yd6">
                        <p className="landing_block_subtitle Text_container__fiiSE Text_caption16__dSWBt ">Platform <span className="highlighted">The Best!</span></p>
                      </div>
                      <p className="landing_block_desc mt-4">
                        <span className="text-white">Safety & Reliability.</span> Our trading platform is based on the most popular analytical trading system, MetaTrader 5. Your data is protected and is inaccessible to hackers. It is the best choice for the modern trader. The trading platform has won the prestigious Finance Magnates Awards 2020 in two different categories. MetaTrader 5 was categorized as the best Forex trading and multi-asset trading platform.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="landing-chart-box">
                      <img className="img-responsive" src="/assets/home/img/simple-ipad-2.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-page-section landing_white" id="benefits">
          <div className="landing_container__tKlKL">
            <div className="landing_toolsPromo__ZbyEZ">
              <div className="react-reveal landing_shadow_2__kvs7c"></div>
              <img src="/assets/home/img/simple-ipad-3.png" className="landing_ipad__UiDcp" alt="img" />
              <p className="Text_container__fiiSE Text_caption20__XC_FX">And most importantly...</p>
              <h1>Created by Traders,<br />For Traders.</h1>
            </div>
            <footer className="Footer_footer__g5y_B d-none">
              <p className="Text_container__fiiSE Text_body16__qNlL1">© 2021 Renote. All rights reserved.</p>
              <p className="Text_container__fiiSE Text_body16__qNlL1">Contact us at <a href="mailto:support@renote.so">hello@renote.so</a></p>
            </footer>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default withRouter(HomePage);
