import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  get_data_value,
} from "utils/GlobalFunctions";
import { 
  yesNoOptions, 
  sourceWealthOptions, 
  exchangeSecurityYearsExperienceOptions,
  leverageMetalYearsExperienceOptions,
  commoditiesYearsExperienceOptions,
  futureYearsExperienceOptions,
  leverageMetalFrequencyTradingOptions,
  commoditiesFrequencyTradingOptions,
  futureFrequencyTradingOptions,
  purposeAccountOptions,
  managementAccountOptions,
 } from "../constants";

const IndividualUserPreferencesBlock = (props) => {
  const { setUserPreferenceData, errorField, setErrorField } = props; 
  const initUserData = {
    political_exposed_person: yesNoOptions[0]['value'],
    involved_terrorist: yesNoOptions[0]['value'],
    source_wealth: sourceWealthOptions[0]['value'],
    watched_compliance: false,
    previous_experience_trading: yesNoOptions[0]['value'],
    exchange_security_years_experience: exchangeSecurityYearsExperienceOptions[0]['value'],
    leverage_metals_years_experience: leverageMetalYearsExperienceOptions[0]['value'],
    leverage_metals_frequancy_trading: leverageMetalFrequencyTradingOptions[0]['value'],
    commodities_years_experience: commoditiesYearsExperienceOptions[0]['value'],
    commodities_frequancy_trading: commoditiesFrequencyTradingOptions[0]['value'],
    futures_years_experience: futureYearsExperienceOptions[0]['value'],
    futures_frequancy_trading: futureFrequencyTradingOptions[0]['value'],
    purpose_account: purposeAccountOptions[0]['value'],
    manage_account: managementAccountOptions[0]['value'],
    purpose_account_other: "",
  };
  const [userData, setUserData] = useState(initUserData);
  
  useEffect(() => {
    setUserPreferenceData(userData)
  }, [userData]);

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updateData = {...userData}
    updateData[field_name] = field_value;
    setUserData({ ...updateData });
    setUserPreferenceData(updateData)    
  };
  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updateData = {...userData}
    updateData[field_name] = checked;
    setUserData({ ...updateData })
    setUserPreferenceData(updateData)    
  };

  return (
    <div className="user-preferences-block">
      <div className="row">  
        <div className="col-md-12">
          <div className="user-personal-block">
            <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
              Personal:
            </h6>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Are you a Political exposed Person? (PEP)</label>
                  <select
                    id="political_exposed_person"
                    name="political_exposed_person"
                    className={
                      "form-control appearence" +
                      (errorField.includes("political_exposed_person")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "political_exposed_person",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    Are you involved in a terrorist cell or any criminal
                    activity?
                  </label>
                  <select
                    id="involved_terrorist"
                    name="involved_terrorist"
                    className={
                      "form-control appearence" +
                      (errorField.includes("involved_terrorist")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "involved_terrorist", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Source of wealth:</label>
                  <select
                    id="source_wealth"
                    name="source_wealth"
                    className={
                      "form-control appearence" +
                      (errorField.includes("source_wealth")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "source_wealth", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {sourceWealthOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <p>
                  Decralation: <br />
                  I/We declare (as an authorized signatory for the applicant)
                  that the information provided in this form, is to the best of
                  my knowledge and belief, accurate and complete. I/We further
                  confirm that my/our wealth is from legitimate sources as
                  indicated above, and that I/We will also provide the required
                  evidence of the source of wealth as is required.
                </p>
                <div className="custom-checkbox form-group">
                  <input
                    type="checkbox"
                    id="watched-compliance"
                    name="watched_compliance"
                    checked={userData['watched_compliance']}
                    onChange={(e) => {
                      onChangeFormCheckboxField(e);
                    }}
                  />
                  <label htmlFor="watched-compliance">&nbsp;Agree</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-trading-experience-block">
            <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
              Trading Experience:
            </h6>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Do you have any previous experience Trading?</label>
                  <select
                    id="previous_experience_trading"
                    name="previous_experience_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("previous_experience_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "previous_experience_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Exchange Securities:</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="exchange_security_years_experience"
                    name="exchange_security_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("exchange_security_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "exchange_security_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {exchangeSecurityYearsExperienceOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">
                  Leverage FX, CFD ́s and precious metals
                </label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="leverage_metals_years_experience"
                    name="leverage_metals_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("leverage_metals_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "leverage_metals_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {leverageMetalYearsExperienceOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Frequency of trading:</label>
                  <select
                    id="leverage_metals_frequancy_trading"
                    name="leverage_metals_frequancy_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("leverage_metals_frequancy_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "leverage_metals_frequancy_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {leverageMetalFrequencyTradingOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Commodities</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="commodities_years_experience"
                    name="commodities_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("commodities_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "commodities_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {commoditiesYearsExperienceOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Frequency of trading:</label>
                  <select
                    id="commodities_frequancy_trading"
                    name="commodities_frequancy_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("commodities_frequancy_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "commodities_frequancy_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {commoditiesFrequencyTradingOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Futures</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="futures_years_experience"
                    name="futures_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("futures_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "futures_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {futureYearsExperienceOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Frequency of trading:</label>
                  <select
                    id="futures_frequancy_trading"
                    name="futures_frequancy_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("futures_frequancy_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "futures_frequancy_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {futureFrequencyTradingOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Account</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Purpose of the account:</label>
                  <select
                    id="purpose_account"
                    name="purpose_account"
                    className={
                      "form-control appearence" +
                      (errorField.includes("purpose_account")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "purpose_account", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {purposeAccountOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="block">
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        "form-control"                         
                      }
                      id="purpose_account_other"
                      name="purpose_account_other"
                      value={get_data_value(userData, 'purpose_account_other')}
                      placeholder="Other"                     
                      onChange={(e) => {
                        onChangeFormField(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Management of the account:</label>
                  <select
                    id="manage_account"
                    name="manage_account"
                    className={
                      "form-control appearence" +
                      (errorField.includes("manage_account")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "manage_account", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {managementAccountOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualUserPreferencesBlock
