import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import IndividualRegisterPage0 from "pages/Home/IndividualRegisterPage/IndividualRegisterPage0";

import { ROUTE_REGISTER, ROUTE_ROOT, ROUTE_COMPANY_REGISTER, ROUTE_INDIVIDUAL_REGISTER, ROUTE_USER_DASHBOARD, ROUTE_LOGIN } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { withRouter } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import { curl_post, downloadURI, empty, intval, is_empty, showToast, show_loading } from "utils/GlobalFunctions";
import { apiLogout, apiUserRegister } from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { REGISTER_FUNC } from "config/CONSTANTS";
import { console_log } from "utils/logger";
import { urlDownloadFile } from "services/CONSTANTS";

const IndividualRegisterPage = (props) => {
  //const query_params = new URLSearchParams(props.location.search);
  //const ref_id = query_params.get("ref");
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Register as Individual";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_REGISTER,
        license_cancelled_message: "",
      })
    );
  };

  const [pageData, setPageData] = useState({});
  const [regStep, setRegStep] = useState(0);

  useEffect(() => {
    if (REGISTER_FUNC === "disabled") {
      showToast(
        "Registration temporarily disabled! Please check back later",
        "error"
      );
      props.history.replace({ pathname: ROUTE_ROOT });
    }
    initPage();
    doLogout();

    let step = props.match.params.step;
    if (is_empty(step)) {
      step = 0;
    } else {
      step = intval(step)
    }
    setRegStep(step);
  }, [props]);

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }
    apiLogout(userDataStore["token"])
      .then((api_res) => { })
      .catch((err) => { });
    dispatch(updateUser({ token: "" }));
  };
  const goRegStep = (step) => {
    setRegStep(step);
    console_log('step', step)
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
  };

  const onSubmit = (reg_data) => {
    console_log("onSubmit register data:::", reg_data);
    const userData = reg_data['userData']

    show_loading(true);
    apiUserRegister(userData)
      .then((api_res) => {
        console_log("api_res", api_res);
        if (api_res.status === "1") {
          let data = api_res["data"];
          showToast(api_res.message, "success");
          doRegister(data);
        } else {
          show_loading(false);
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  }

  const doRegister = (data) => {
    const contract_doc = data['contract_doc']
    if (contract_doc) {
      downloadPDF(contract_doc, "MYBROKERFX_AGREEMENT.pdf")
    }
    setTimeout(function () {
      show_loading(false);
      props.history.replace({ pathname: ROUTE_LOGIN });
    }, 500)
  }

  const downloadPDF = (file_path, download_name) => {
    let post_param = {
      file_path: file_path,
      download_name: download_name
    }
    curl_post(urlDownloadFile, post_param, 'post', '_blank')
  }

  return (
    <div>
      <HomeLayout>
        <div className="landing-page-section landing_black">
          <div className="landing_container bg-img">
            <HomeLogoBar></HomeLogoBar>

            <div className="container">
              <div className="form-container registratoin-form-container">
                <form className="reg-form bg-gradient-1" method="post">
                  <div className="reg-form-content bg-overlayer">
                    <h3 className="text-center text-white mt-0 mb-4">
                      Registration
                    </h3>
                    {regStep === 0 && (
                      <IndividualRegisterPage0
                        pageData={pageData}
                        setPageData={setPageData}
                        onSubmit={(e) => onSubmit(e)}
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default withRouter(IndividualRegisterPage);
