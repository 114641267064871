export const yesNoOptions = [
    {
        value: 1,
        text: "Yes",
    },
    {
        value: 0,
        text: "No",
    },
];

export const sourceWealthOptions = [
    {
        value: 0,
        text: "Income from profession/emplyment",
    },
    {
        value: 1,
        text: "Company Ownership/Sale of Company Assets",
    },
    {
        value: 2,
        text: "Investments or Savings",
    },
    {
        value: 3,
        text: "Other",
    },
];
export const exchangeSecurityYearsExperienceOptions = [
    {
        value: 0,
        text: "None",
    },
    {
        value: 1,
        text: "0-2 years",
    },
    {
        value: 2,
        text: "2-5 years",
    },
    {
        value: 3,
        text: "5-10 years",
    },
    {
        value: 4,
        text: "More than 10 years",
    },
];
export const leverageMetalYearsExperienceOptions = exchangeSecurityYearsExperienceOptions;
export const commoditiesYearsExperienceOptions = exchangeSecurityYearsExperienceOptions;
export const futureYearsExperienceOptions = exchangeSecurityYearsExperienceOptions;
export const leverageMetalFrequencyTradingOptions = [
    {
        value: 0,
        text: "None",
    },
    {
        value: 1,
        text: "Daily",
    },
    {
        value: 2,
        text: "Weekly",
    },
    {
        value: 3,
        text: "Monthly",
    },
    {
        value: 4,
        text: "Quarterly",
    },
    {
        value: 5,
        text: "Semi-annual",
    },
];

export const commoditiesFrequencyTradingOptions = leverageMetalFrequencyTradingOptions;
export const futureFrequencyTradingOptions = leverageMetalFrequencyTradingOptions;
export const purposeAccountOptions = [
    {
        value: 0,
        text: "Hedging",
    },
    {
        value: 1,
        text: "Speculation",
    },
    {
        value: 2,
        text: "Property",
    },
    {
        value: 3,
        text: "Other",
    },
];
export const managementAccountOptions = [
    {
        value: 0,
        text: "Self-Management",
    },
    {
        value: 1,
        text: "Third Party Manager",
    },
    {
        value: 2,
        text: "You Act As A Money Manager",
    },
    {
        value: 3,
        text: "You Are Going To Use E.A. To Manage Your Account",
    },
];
export const documentType = [
    {
        value: 'passport',
        text: "Passport",
    },
    {
        value: "id_card",
        text: "ID Card",
    }
];