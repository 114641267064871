import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./HomeLayout.css";
import HomeBottom from "./Includes/HomeBottom";
import HomeChatBox from "./Includes/HomeChatBox";
import HomeFooter from "./Includes/HomeFooter";
import HomeHeader from "./Includes/HomeHeader";
import HomeLoader from "./Includes/HomeLoader";

function HomeLayout(props) {
    const appDataStore = useSelector((x) => x.appDataStore);

    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'auto';
        window.scrollTo(0, 0);
      }, [])

    return (
        <div>
            <div className="home-page">
                <HomeHeader></HomeHeader>
                <HomeLoader></HomeLoader>
               
                <main id="main" className="">{props.children}</main>
                
                <HomeFooter></HomeFooter>
                <HomeChatBox></HomeChatBox>
                <HomeBottom></HomeBottom>
            </div>
        </div>
    );
}
export default HomeLayout;
