import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_ROOT,
  ROUTE_REGISTER,
  ROUTE_COMPANY_REGISTER,
  ROUTE_INDIVIDUAL_REGISTER,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { USER_TYPE } from "config/CONSTANTS";
import { Link, withRouter } from "react-router-dom";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";

const RegisterPage = (props) => {
  const dispatch = useDispatch();
  const initPage = () => {
    let page_title = "Register";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_REGISTER,
      })
    );
  };

  useEffect(() => {
    initPage();
  }, []);

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const userInfo = userDataStore;
  const initUserType = null;
  const [userType, setUserType] = useState(initUserType);

  const regUserType = (type) => {
    setUserType(type);
    if (type === USER_TYPE.INDIVIDUAL) {
      props.history.push({ pathname: ROUTE_INDIVIDUAL_REGISTER });
    } else if (type === USER_TYPE.COMPANY) {
      props.history.push({ pathname: ROUTE_COMPANY_REGISTER });
    }
  };

  return (
    <div>
      <HomeLayout>
        <div className="landing-page-section landing_black">
          <div className="landing_container bg-img">
            <HomeLogoBar></HomeLogoBar>
            <div className="container">
              <div className="reg-form-container">
                <form className="reg-form bg-gradient-1" method="post">
                  <div className="reg-form-content bg-overlayer">
                    <h3 className="text-center text-white mt-0 mb-4">
                      Are you creating an account for an individual or a
                      company?
                    </h3>
                    <div className="reg-option-list valign">
                      <div
                        className={`btn-reg-option valign ${
                          userType === USER_TYPE.INDIVIDUAL ? "active" : ""
                        }`}
                        onClick={(e) => {
                          regUserType(USER_TYPE.INDIVIDUAL);
                        }}
                        type="button"
                      >
                        Individual
                      </div>
                      <div
                        className={`btn-reg-option valign ${
                          userType === USER_TYPE.COMPANY ? "active" : ""
                        }`}
                        onClick={(e) => {
                          regUserType(USER_TYPE.COMPANY);
                        }}
                        type="button"
                      >
                        Company
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default withRouter(RegisterPage);
