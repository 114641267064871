import { ROUTE_LOGIN } from "navigation/CONSTANTS";

export const HOME_MENU_ITEMS = [
  {
    route: "#why-choose",
    route_type: "a",
    icon: "",
    title: "Why Choose My Broker FX?",
    level: ""
  },
  {
    route: "#benefits",
    route_type: "a",
    icon: "",
    title: "Benefits",
    level: ""
  },
  {
    route: "#contact",
    route_type: "a",
    icon: "",
    title: "Contact",
    level: ""
  },
  {
    route: ROUTE_LOGIN,
    route_type: "link",
    icon: "",
    title: "Login",
    level: ""
  }
];
