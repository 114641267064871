import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import CompanyRegisterPage0 from "pages/Home/CompanyRegisterPage/CompanyRegisterPage0";
import CompanyRegisterPage1 from "pages/Home/CompanyRegisterPage/CompanyRegisterPage1";
import CompanyRegisterPage2 from "pages/Home/CompanyRegisterPage/CompanyRegisterPage2";
import CompanyRegisterPage3 from "pages/Home/CompanyRegisterPage/CompanyRegisterPage3";

import {
  ROUTE_REGISTER,
  ROUTE_ROOT,
  ROUTE_COMPANY_REGISTER,
  ROUTE_LOGIN,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { withRouter } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import { curl_post, empty, intval, is_empty, showToast, show_loading } from "utils/GlobalFunctions";
import { apiCompanyRegister, apiLogout } from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { REGISTER_FUNC } from "config/CONSTANTS";
import { console_log } from "utils/logger";
import { updatePageData } from "redux/actions/pageDataActions";
import { countryList } from "utils/countryList";
import { urlDownloadFile } from "services/CONSTANTS";

const CompanyRegisterPage = (props) => {
  //const query_params = new URLSearchParams(props.location.search);
  const pageDataStore = useSelector((x) => x.pageDataStore);

  const defaultCompanyInfo = {
    name: "quanseng",
    phone: "1232323232",
    email: "quanseng@gmail.com",
    password: "123",
    password1: "123",
    address: "address",
    country: countryList[0]['code'],
    city: "city",
    zip_code: "1234",
    state: "state",
    moved_address: "moved_address",
    company_website: "company_website",
    contact_id: "contact_id",
    dob: new Date()
  }

  const dispatch = useDispatch();
  const initPage = () => {
    console_log("++++++++++++++++++++++++initPage++++++++++++++++++++++++");
    let page_title = "Register as Company";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_REGISTER,
        license_cancelled_message: "",
      })
    );

    dispatch(
      updatePageData({
        companyRegister: {
          step0: defaultCompanyInfo,
          step1: {
            share_holder_count: 1,
            director_count: 1,
            share_holder_list: {},
            director_list: {},
          },
          step2: null,
        },
      })
    );
  };

  const [pageData, setPageData] = useState({});
  const [regStep, setRegStep] = useState(0);

  useEffect(() => {
    initPage();
    doLogout();

    setRegStep(0);
  }, []);

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }
    apiLogout(userDataStore["token"])
      .then(() => { })
      .catch(() => { });
    dispatch(updateUser({ token: "" }));
  }

  const goRegStep = (step) => {
    setRegStep(step);
    console_log("step", step);
    //props.history.push({ pathname: ROUTE_COMPANY_REGISTER + "/" + step }); //for tmp
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
  }

  const onSubmit = () => {
    const registerData = { ...pageDataStore["companyRegister"] }
    console_log("registerData::::", registerData)
    show_loading(true);
    apiCompanyRegister(registerData)
      .then((api_res) => {
        console_log("api_res", api_res);
        if (api_res.status === "1") {
          let data = api_res["data"];
          show_loading(false);
          showToast(api_res.message, "success");
          doRegister(data);
        } else {
          show_loading(false);
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  }

  const doRegister = (data) => {
    const contract_doc = data['contract_doc']
    if (contract_doc) {
      downloadPDF(contract_doc, "MYBROKERFX_AGREEMENT.pdf")
    }
    setTimeout(function () {
      show_loading(false);
      props.history.replace({ pathname: ROUTE_LOGIN });
    }, 500)
  }

  const downloadPDF = (file_path, download_name) => {
    let post_param = {
      file_path: file_path,
      download_name: download_name
    }
    curl_post(urlDownloadFile, post_param, 'post', '_blank')
  }


  return (
    <div>
      <HomeLayout>
        <div className="landing-page-section landing_black">
          <div className="landing_container bg-img">
            <HomeLogoBar></HomeLogoBar>

            <div className="container">
              <div className="form-container registratoin-form-container">
                <form className="reg-form bg-gradient-1" method="post">
                  <div className="reg-form-content bg-overlayer">
                    <h3 className="text-center text-white mt-0 mb-4">
                      Registration
                    </h3>
                    {regStep === 0 && (
                      <CompanyRegisterPage0 goRegStep={goRegStep} />
                    )}
                    {regStep === 1 && (
                      <CompanyRegisterPage1 goRegStep={goRegStep} />
                    )}
                    {regStep === 2 && (
                      <CompanyRegisterPage2 goRegStep={goRegStep} />
                    )}
                    {regStep === 3 && (
                      <CompanyRegisterPage3 goRegStep={goRegStep} onSubmit={() => onSubmit()} />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default withRouter(CompanyRegisterPage);
