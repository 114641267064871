import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  is_null,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiLogout,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { SMS_FUNC } from "config/CONSTANTS";
import { console_log } from "utils/logger";
import {
  totalEstimatedNetWorthOptions,
  totalEstimatedAnnualOptions,
  sourceIncomeOptions,
  yesNoOptions,
  companyDocumentOptions
} from "./constants";
import MyUploadBox from "components/MyUploadBox/MyUploadBox";
import { updatePageData } from "redux/actions/pageDataActions";

const CompanyRegisterPage2 = (props) => {
  const { goRegStep } = props;
  const curStep = 2;

  const pageDataStore = useSelector((x) => x.pageDataStore);
  console_log("=====================pageData step2=====================", pageDataStore["companyRegister"]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!is_null(pageDataStore["companyRegister"]["step2"])) {
      setUserData(pageDataStore["companyRegister"]["step2"]);
    }
    console_log('pageDataStore["companyRegister"]', pageDataStore["companyRegister"])
  }, [pageDataStore]);
  //////////////////////////////////////////////////////////////////

  const userDataStore = useSelector((x) => x.userDataStore);
  const initUserData = {
    total_estimated_net_worth: totalEstimatedNetWorthOptions[0]['value'],
    total_estimated_annual_income: totalEstimatedAnnualOptions[0]['value'],
    source_income: sourceIncomeOptions[0]['value'],
    employment_status: yesNoOptions[0]['value'],
    forex_other_instruments: yesNoOptions[0]['value'],
    product_sutable: yesNoOptions[0]['value'],
    has_previous_work: yesNoOptions[0]['value'],
    company_document: companyDocumentOptions[0]['value']
  };

  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "upload_company_document", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    dispatchPageData({ ...userData })
  }

  const onChangeFile = (files, field_name, single = true) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    const updateData = { ...userData }
    if (files && files.length > 0) {
      updateData[field_name] = files[0];
    }
    setUserData({ ...updateData });
    dispatchPageData({ ...updateData })
  };

  const dispatchPageData = (user_data) => {
    if (empty(user_data)) {
      user_data = { ...userData }
    }
    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          step2: user_data
        },
      })
    );
  }

  const nextRegStep = () => {
    const is_valid = validateFields()
    if (is_valid) {
      dispatchPageData()
      goRegStep(curStep + 1);
    } else {
      setTimeout(function () {
        if (jQuery(".is-invalid").length > 0) {
          console_log('jQuery(".is-invalid").offset().top', jQuery(".is-invalid").offset())
          const scrollTop = jQuery(".is-invalid").offset().top - 20
          window.scrollTo(0, (scrollTop > 0 ? scrollTop : 0));
        }
      }, 200)
    }
  }

  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  };

  return (
    <div className="reg-form">
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Total Estimated Net Worth($)?</label>
                  <select
                    id="total_estimated_net_worth"
                    name="total_estimated_net_worth"
                    className={
                      "form-control appearence" +
                      (errorField.includes("total_estimated_net_worth")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "total_estimated_net_worth",
                      totalEstimatedNetWorthOptions[0]['value']
                    )}
                  >
                    {totalEstimatedNetWorthOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Total Estimated Annual Income($)?</label>
                  <select
                    id="total_estimated_annual_income"
                    name="total_estimated_annual_income"
                    className={
                      "form-control appearence" +
                      (errorField.includes("total_estimated_annual_income")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "total_estimated_annual_income",
                      totalEstimatedAnnualOptions[0]['value']
                    )}
                  >
                    {totalEstimatedAnnualOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Source of Income:</label>
                  <select
                    id="source_income"
                    name="source_income"
                    className={
                      "form-control appearence" +
                      (errorField.includes("source_income")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "source_income", sourceIncomeOptions[0]['value'])}
                  >
                    {sourceIncomeOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Employment Status:</label>
                  <select
                    id="employment_status"
                    name="employment_status"
                    className={
                      "form-control appearence" +
                      (errorField.includes("employment_status")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "employment_status", yesNoOptions[0]['value'])}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <h6 className="main-font-color">Trading Experience:</h6>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Forex and Other Market Instruments:</label>
                  <select
                    id="forex_other_instruments"
                    name="forex_other_instruments"
                    className={
                      "form-control appearence" +
                      (errorField.includes("forex_other_instruments")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "forex_other_instruments",
                      yesNoOptions[0]['value']
                    )}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Derivative Products are Suitable as Part of my Investment
                    Objectives and Attitude Towards Risk and I'am Able to Access
                    the Risk Involved in Trading Them, Including the Possibility
                    that I may Lose All of my Capital:
                  </label>
                  <select
                    id="product_sutable"
                    name="product_sutable"
                    className={
                      "form-control appearence" +
                      (errorField.includes("product_sutable")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "product_sutable", yesNoOptions[0]['value'])}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    I Have Previous Professional Qualifications and/or Work
                    Experience in the Financial Services Industry:
                  </label>
                  <select
                    id="has_previous_work"
                    name="has_previous_work"
                    className={
                      "form-control appearence" +
                      (errorField.includes("has_previous_work")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "has_previous_work", yesNoOptions[0]['value'])}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-10 offset-md-1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Company Document</label>
                      <select
                        id="company_document"
                        name="company_document"
                        className={
                          "form-control appearence" +
                          (errorField.includes("company_document")
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => {
                          onChangeFormField(e);
                        }}
                        value={get_data_value(userData, "company_document", companyDocumentOptions[0]['value'])}
                      >
                        {/* <option value="">Select</option> */}
                        {companyDocumentOptions.map((optionItem, index) => (
                          <option key={index} value={optionItem.value}>
                            {optionItem.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>&nbsp;</label>
                      <div className={`file-upload-group ${(errorField.includes("upload_company_document") ? "is-invalid" : "")}`}>
                        <MyUploadBox
                          callbackAcceptedFiles={(files) => {
                            console_log("callbackAcceptedFiles:::", files)
                            onChangeFile(files, 'upload_company_document')
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyRegisterPage2;
