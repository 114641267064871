import { UPDATE_PAGE_DATA } from "redux/actions/pageDataActions";

const initialState = {};

export const pageDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
