import CompanyUserBlock from "pages/Home/CompanyRegisterPage/inc/CompanyUserBlock";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  get_data_value,
  isEmpty,
  genNumArr,
  is_null,
  jQuery,
  showToast,
  genEmptyArr,
  empty,
  intval,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import { updatePageData } from "redux/actions/pageDataActions";

const CompanyRegisterPage1 = (props) => {
  const { goRegStep } = props;
  const curStep = 1;
  const boxCount = 4;
  let boxDropdownOptionList = genNumArr(boxCount);

  const pageDataStore = useSelector((x) => x.pageDataStore);
  console_log("=====================pageData step1=====================", pageDataStore["companyRegister"]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!is_null(pageDataStore["companyRegister"]["step1"])) {
      setUserData(pageDataStore["companyRegister"]["step1"]);
      setBoxShareHolderNumList(genNumArr(pageDataStore["companyRegister"]["step1"]['share_holder_count']))
      setBoxDirectorNumList(genNumArr(pageDataStore["companyRegister"]["step1"]['director_count']))
    }
  }, [pageDataStore]);
  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const [userData, setUserData] = useState({});
  const [errorField, setErrorField] = useState([]);

  const validateUserBox = (formData) => {
    var errorList = [];
    errorList = isEmpty(formData, "first_name", errorList);
    errorList = isEmpty(formData, "last_name", errorList);
    errorList = isEmpty(formData, "phone", errorList);
    errorList = isEmpty(formData, "email", errorList);
    errorList = isEmpty(formData, "country", errorList);
    errorList = isEmpty(formData, "city", errorList);
    errorList = isEmpty(formData, "zip_code", errorList);
    errorList = isEmpty(formData, "state", errorList);
    errorList = isEmpty(formData, "moved_address", errorList);
    errorList = isEmpty(formData, "dob", errorList);
    return errorList;
  }
  const validateFields = () => {
    var is_valid = true;
    var new_share_holder_list = {};
    var new_director_list = {};
    var share_holder_error_field_list = {};
    var director_error_field_list = {};
    const share_holder_list = pageDataStore["companyRegister"]["step1"]['share_holder_list']
    if (!empty(share_holder_list)) {
      for (let boxNum in share_holder_list) {
        if(boxShareHolderNumList.includes(intval(boxNum))) {
          const user_info = share_holder_list[boxNum]
          var errorList = validateUserBox(user_info)
          if (errorList.length > 0) {
            is_valid = false
          }
          share_holder_error_field_list[boxNum] = [...errorList]
          new_share_holder_list[boxNum] = user_info
        }
      }
    }

    const director_list = pageDataStore["companyRegister"]["step1"]['director_list']
    if (!empty(director_list)) {
      for (let boxNum in director_list) {
        console_log("boxNum:::",boxNum)
        if(boxDirectorNumList.includes(intval(boxNum))) {
          const user_info = director_list[boxNum]
          var errorList = validateUserBox(user_info)
          if (errorList.length > 0) {
            is_valid = false
          }
          director_error_field_list[boxNum] = [...errorList]
          new_director_list[boxNum] = user_info
        }       
      }
    }
    dispatchErrorData(share_holder_error_field_list, director_error_field_list, new_share_holder_list, new_director_list)
    return is_valid
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    if (field_name === "share_holder_count") {
      let optionList = [];
      optionList = genNumArr(field_value);
      setBoxShareHolderNumList(optionList);
    }
    if (field_name === "director_count") {
      let optionList = [];
      optionList = genNumArr(field_value);
      setBoxDirectorNumList(optionList);
    }
    dispatchPageData()
  };

  const dispatchPageData = () => {
    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          step1: userData
        },
      })
    );
  }

  const dispatchErrorData = (share_holder_error_field_list, director_error_field_list, new_share_holder_list, new_director_list) => {
    let tmpData = { ...pageDataStore["companyRegister"] }
    tmpData["step1"]['share_holder_list'] = {...new_share_holder_list}
    tmpData["step1"]['director_list'] = {...new_director_list}
    
    for (let boxNum in share_holder_error_field_list) {
      tmpData["step1"]['share_holder_list'][boxNum]['errorField'] = share_holder_error_field_list[boxNum]
    }
    for (let boxNum in director_error_field_list) {
      tmpData["step1"]['director_list'][boxNum]['errorField'] = director_error_field_list[boxNum]
    }
    dispatch(
      updatePageData({
        companyRegister: {
          ...pageDataStore["companyRegister"],
          ...tmpData
        },
      })
    );
  }

  const [boxShareHolderNumList, setBoxShareHolderNumList] = useState([1]);
  const [boxDirectorNumList, setBoxDirectorNumList] = useState([1]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const nextRegStep = () => {
    console_log("userData:::", userData)
    const is_valid = validateFields();
    if (is_valid) {

      dispatchPageData()
      goRegStep(curStep + 1);
    } else {
      setTimeout(function () {
        if (jQuery(".is-invalid").length > 0) {
          console_log('jQuery(".is-invalid").offset().top', jQuery(".is-invalid").offset())
          const scrollTop = jQuery(".is-invalid").offset().top - 20
          window.scrollTo(0, (scrollTop > 0 ? scrollTop : 0));
        }
      }, 200)
    }
  }
  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  };

  return (
    <div className="reg-form">
      <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
        Company Shareholders & Directors:
      </h6>
      <div className="row">
        <div className="col-md-6">
          <div className="shareholders-wrapper">
            <div className="form-group">
              <label>Number of Shareholders:</label>
              <select
                id="share_holder_count"
                name="share_holder_count"
                className={`form-control appearence`}
                onChange={(e) => {
                  onChangeFormField(e);
                }}
                value={get_data_value(userData, "share_holder_count", "1")}
              >
                {boxDropdownOptionList.map((optionItem, index) => (
                  <option key={index} value={optionItem}>
                    {optionItem}
                  </option>
                ))}
              </select>
            </div>

            <div className="company-share-holder-list">
              {boxShareHolderNumList.map((val, index) => {
                return (
                  <CompanyUserBlock
                    key={index}
                    boxNum={val}
                    boxClass="company-share-holder-box"
                    boxName="ShareHolder"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="directors-wrapper">
            <div className="form-group">
              <label>Number of Directors:</label>
              <select
                id="director_count"
                name="director_count"
                className={`form-control appearence`}
                onChange={(e) => {
                  onChangeFormField(e);
                }}
                value={get_data_value(userData, "director_count", "1")}
              >
                {boxDropdownOptionList.map((optionItem, index) => (
                  <option key={index} value={optionItem}>
                    {optionItem}
                  </option>
                ))}
              </select>
            </div>

            <div className="company-director-list">
              {boxDirectorNumList.map((val, index) => {
                return (
                  <CompanyUserBlock
                    key={index}
                    boxNum={val}
                    boxClass="company-director-box"
                    boxName="Director"
                  />
                )
              })}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="reg-btn-group reg-btn-group-full  text-center">
            <button
              className="btn btn-danger btn-reg-prev"
              type="button"
              onClick={(e) => {
                prevRegStep();
              }}
            >
              Back
            </button>
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyRegisterPage1;
