import { empty, getDateFromObject, get_utc_timestamp, timeConverter } from "utils/GlobalFunctions";
import { axiosDelete, axiosGet, axiosPost, axiosPostMultipart, axiosPut } from "./ajaxServices";
import { urlAccountVerification, urlCheckAuthSms, urlCheckCoupon, urlCheckPasswordStrength, urlCheckSponsor, urlCheckTwoFactAuth, urlCompanyRegister, urlConfirmPassword, urlGuestNewTicket, urlLogin, urlLoginTwoFactAuth, urlLogout, urlRegister, urlRequestResetPassword, urlSendAuthSms, urlUserRegister, urlUserSubmitTicket } from "./CONSTANTS";

export function apiUserRegister(params) {
  const url = urlUserRegister;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  const userGeneralData = {...params.userGeneralData}
  userGeneralData['dob'] = getDateFromObject(userGeneralData['dob'])
  formData.append("userGeneralData", JSON.stringify(userGeneralData));
  formData.append("userPreferenceData", JSON.stringify(params.userPreferenceData));

  formData.append("sigDataUrl", params.sigDataUrl);  // png base64 data for signature
  formData.append("signDate", timeConverter(get_utc_timestamp()));  // png base64 data for signature
  

  Object.keys(params.fileData).forEach(k => {
    formData.append(k, params.fileData[k]);
  });

  return new Promise((resolve, reject) => {
    axiosPostMultipart(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiCompanyRegister(params) {
  const url = urlCompanyRegister;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  const companyGeneralData = {...params.step0}
  const companyUsersData = {...params.step1}
  const companyPreferenceData = {...params.step2}
  const companyUserDocumentData = [...params.step3]
  
  companyGeneralData['dob'] = getDateFromObject(companyGeneralData['dob'])
  for(let num in companyUsersData['share_holder_list']) {
    companyUsersData['share_holder_list'][num]['dob'] = getDateFromObject(companyUsersData['share_holder_list'][num]['dob'])
  }
  for(let num in companyUsersData['director_list']) {
    companyUsersData['director_list'][num]['dob'] = getDateFromObject(companyUsersData['director_list'][num]['dob'])
  }

  formData.append("companyPreferenceDocument", companyPreferenceData['upload_company_document']);
  delete companyPreferenceData['upload_company_document'];
  delete companyGeneralData['sigDataUrl']

  formData.append("companyGeneralData", JSON.stringify(companyGeneralData));
  formData.append("companyUsersData", JSON.stringify(companyUsersData));
  formData.append("companyPreferenceData", JSON.stringify(companyPreferenceData));

  formData.append("sigDataUrl", params.step0['sigDataUrl']);  // png base64 data for signature
  formData.append("signDate", timeConverter(get_utc_timestamp()));  // png base64 data for signature

  for(let num in companyUserDocumentData) {
    const userDocument = companyUserDocumentData[num]
    Object.keys(userDocument).forEach(k => {
      formData.append(k + "__" + num, userDocument[k]);
    })
  }
  formData.append("companyUserDocumentCount", companyUserDocumentData.length);

  return new Promise((resolve, reject) => {
    axiosPostMultipart(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiLogin(userData) {
  const url = urlLogin;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", userData.name);
  formData.append("password", userData.password);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiLoginTwoFactAuth(userData, codeStr) {
  const url = urlLoginTwoFactAuth;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", userData.name);
  formData.append("password", userData.password);
  formData.append("code", codeStr);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiSendAuthSms(params) {
  const url = urlSendAuthSms;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("user_phone", params.user_phone);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiCheckAuthSms(params, code) {
  const url = urlCheckAuthSms;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("user_phone", params.user_phone);
  formData.append("code", code);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiRequestResetPassword(userData) {
  const url = urlRequestResetPassword;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("user_email", userData.user_email);
  formData.append("user_password", userData.user_password);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function apiConfirmPassword(get_params) {
  const url = urlConfirmPassword;
  return new Promise((resolve, reject) => {
    axiosGet(url, get_params, "")
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiAccountVerification(get_params) {
  const url = urlAccountVerification;
  return new Promise((resolve, reject) => {
    axiosGet(url, get_params, "")
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiLogout(token) {
  const url = urlLogout;
  const get_params = { token: token }
  return new Promise((resolve, reject) => {
    axiosGet(url, get_params, "")
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiGuestNewTicket(params) {
  const url = urlGuestNewTicket;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("email", params.email);
  formData.append("title", params.subject);
  formData.append("description", params.description);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiCheckSponsor(params) {
  const url = urlCheckSponsor;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("ref", empty(params.ref) ? "" : params.ref);
  formData.append("default_sponsor", params.default_sponsor)

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiCheckPasswordStrength(password) {
  const url = urlCheckPasswordStrength;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("password", password);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function apiCheckCoupon(params) {
  const url = urlCheckCoupon;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("coupon", empty(params.coupon) ? "" : params.coupon);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const apiUploadFile = (uploadFile) => {
  const url = urlUserSubmitTicket;
  var formData = new FormData();
  formData.append("upload_file", uploadFile);
  return new Promise((resolve, reject) => {
    axiosPostMultipart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};