import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { empty, isEmpty, jQuery, showToast } from "utils/GlobalFunctions";
import { console_log } from 'utils/logger';
import "./MyUploadBox.css";

const MyUploadBox = (props) => {
  const { callbackAcceptedFiles } = props;
  const [files, setFiles] = useState([]);

  const defaultProps = {
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
    maxSize: 2 * 1000 * 1000, //byte
    multiple: false,
    placeholder: "Drag 'n' drop a file here, or click to select a file"
  }

  const uploadProps = {
    ...defaultProps,
    ...props,
  }

  const getUploadPlaceHolder = () => {
    if (uploadProps.multiple) {
      return props.placeholder ? props.placeholder : "Drag 'n' drop files here, or click to select files"
    } else {
      return uploadProps.placeholder
    }
  }

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    ...uploadProps,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      callbackAcceptedFiles(acceptedFiles)
    }
  });


  useEffect(() => {
    if (fileRejections && fileRejections.length > 0) {
      fileRejections.map(({ file, errors }) => {
        //console_log("errors:::",errors)
        errors.map((e) => {
          showToast(e.message, "error")
        })
      });
    }
  }, [fileRejections]);

  const thumbs = files.map(file => {
    //console_log("file:::", file)
    const file_type = file.type;
    return (
      <div className='thumb' key={file.name}>
        {(file_type.includes("image")) ? (
          <div className='thumbInner'>
            <img
              className='img'
              src={file.preview}
              // Revoke data uri after image is loaded
              onLoad={() => { URL.revokeObjectURL(file.preview) }}
            />
          </div>
        ) : (
          <div className='thumbInner'>
            <p className='text-center mb-0 p-2'>{file.name}</p>
          </div>
        )
        }
      </div>
    )
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className={`my-upload-box ${uploadProps.multiple ? "multiple" : "single"}`}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p className='upload-placeholder mb-0'>{getUploadPlaceHolder()}</p>
      </div>
      <aside className='thumbsContainer'>
        {thumbs}
      </aside>
    </section>
  );
}

export default MyUploadBox;
